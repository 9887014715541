import React from 'react';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import emailjs from '@emailjs/browser';

var bnrimg = require('./../../images/banner/fw-pi-img.jpeg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
    handleSubmit = (event) => {
        event.preventDefault();
        emailjs.sendForm('service_erkftqi', 'template_l5xr98s', event.target, '2mJDQ-4u9aEhTLC3t')
            .then((result) => {
                console.log('SUCCESS!', result.status, result.text);
            }, (error) => {
                console.log('FAILED...', error);
            });
    }

    render() {
        const defaultProps = {
            center: {
              lat: 34.073280,
              lng: -118.251410
            },
            zoom: 12
        };
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="Contact Us Form" pagename="Contact us" description="At FW Plant Inspections, we're here to provide you with expert assistance and answers to your inquiries about plant and machinery inspections. Whether you're looking for more information on our services, need help with accessing service data, or have any questions about how we can assist with your specific needs, our dedicated team is ready to help. Reach out to us using the form below, and let's ensure the safety and efficiency of your operations together." bgimage={bnrimg}/>
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 col-sm-12">
                                        <form className="contact-form cons-contact-form bg-gray p-a30" method="post" onSubmit={this.handleSubmit}>
                                            <div className="contact-one">
                                                <div className="section-head">
                                                    <div className="sx-separator-outer separator-left">
                                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                                            <h3 className="sep-line-one">Contact Us</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input name="from_name" type="text" required className="form-control" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" className="form-control" required placeholder="Email" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} className="form-control" required placeholder="Message" defaultValue={""} />
                                                </div>
                                                <div className="text-right">
                                                    <button
                                                        name="submit"
                                                        type="submit"
                                                        value="Submit"
                                                        className="site-button btn-half"
                                                    >
                                                        <span>submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <input type="hidden" name="to_name" value="Recipient Name" />
                                            <input type="hidden" name="to_email" value="recipient@example.com" />
                                        </form>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="contact-info block-shadow bg-white bg-center p-a40" style={{ backgroundImage: 'url(images/background/bg-map.png)' }}>
                                            <div>
                                                <div className="section-head">
                                                    <div className="sx-separator-outer separator-left">
                                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                                            <h3 className="sep-line-one">Info</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sx-icon-box-wraper left p-b30">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Phone number</h5>
                                                        <p>01462 768 660</p>
                                                    </div>
                                                </div>
                                                <div className="sx-icon-box-wraper left p-b30">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Email address</h5>
                                                        <p>info@fwplantinspections.co.uk</p>
                                                    </div>
                                                </div>
                                                <div className="sx-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0">Address info</h5>
                                                        <p>Hertfordshire & Surrounding</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gmap-outline">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent lat={34.073280} lng={-118.251410} text={<i className="fa fa-map-marker" />} />
                        </GoogleMapReact>                        
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ContactUs;
