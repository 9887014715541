import React from 'react';
import { NavLink } from 'react-router-dom';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/cherry-picker-banner.jpg');

class AccessEquip extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                <Banner title="Access Equipment Inspections" pagename="Access Equipment Inspections" description="Ensure safety & compliance with our Access Equipment Inspections. Expert evaluations for peak performance. Book your inspection today!" bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* GALLERY CONTENT START */}
                            <div className="row">
                                <div className="col-lg-4 col-md-12 sticky_column">
                                    <div className="about4-section m-b20 bg-gray p-a20">
                                        <div className="widget widget_services">
                                            <h4 className="widget-title">Other Services</h4>
                                            <ul className="bg-white">
                                                <li><NavLink to={"/services/plant-and-machinery-inspection"}>Plant & Machinary Inspections</NavLink></li>
                                                <li><NavLink to={"/services/access-equipment-inspection"}>Access Equipment Installation</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="about4-content m-t30">
                                            <h4>BOOK YOUR INSPECTION TODAY</h4>
                                            <p>If you require plant and machinary inspections please get in touch, we can help!</p>
                                            <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        <div className="service-single-block m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/banner-access.JPG')} alt="" />
                                            </div>
                                            <h3 className="m-t30 sx-tilte">Secure & Compliant: Premier Access Equipment Inspections</h3>
                                            <p>In the fast-paced world of industrial operations, the reliability and safety of your access equipment are non-negotiable. Our Access Equipment Inspection service provides a comprehensive evaluation to ensure your machinery meets all safety standards and regulatory requirements. With a focus on thoroughness and precision, our team of seasoned experts conducts detailed assessments, identifying any potential issues to prevent operational disruptions and ensure a safe working environment. Opt for our inspection service to maintain the integrity of your equipment, safeguard your personnel, and ensure seamless operations with the highest level of safety and compliance.</p>
                                            <h3 className="m-t30 sx-tilte">Why Choose FW Plant inspections?</h3>
                                            <p>Choosing FW Plant Inspections for your Access Equipment Inspections means selecting a partner dedicated to the highest standards of safety, efficiency, and regulatory compliance. Our unique value lies in our unparalleled expertise and commitment to delivering comprehensive inspection services tailored specifically to access equipment. With a team of industry-leading professionals, we bring a depth of knowledge and experience that ensures every inspection is thorough, accurate, and reflective of the latest safety standards. Our use of advanced diagnostic tools allows us to identify potential issues before they become costly or dangerous, providing you with actionable insights to maintain your equipment's integrity.
</p><p>
Moreover, our dedication to customer service sets us apart. Understanding the critical nature of your operations, we offer flexible scheduling to minimize downtime, ensuring your business continues to run smoothly. Our nationwide coverage means we're always where you need us, ready to provide expert inspections across the UK.
</p><p>
By choosing FW Plant Inspections, you're not just getting an inspection service; you're getting a safety partner who values the efficiency and reliability of your operations as much as you do. We don't just inspect; we safeguard your equipment, your people, and your business against the unforeseen, ensuring peace of mind and operational continuity.</p>
                                            {/* <div className="sx-media sx-img-effect zoom-slow"> 
                                                <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                                                    <img src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg" alt=""/>
                                                    <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal6">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>
                                                </div>
                                            </div> */}
                                        </div>
                                        <h4 className="m-t30 sx-tilte">Plant & Machinary Inspections FAQ's</h4>
                                        <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                        What exactly does an Access Equipment Inspection entail?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15"><p>
                                                    
An Access Equipment Inspection involves a comprehensive review and testing of your access machinery, including scissor lifts, boom lifts, and cherry pickers, among others. Our expert team assesses mechanical integrity, safety features, and operational functionality to ensure compliance with UK safety regulations and standards. This meticulous process identifies any potential risks, wear and tear, or malfunctions that could compromise safety or efficiency.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                        How frequently should my access equipment be inspected?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>
                                                    
The frequency of inspections can vary based on the type and usage of the equipment, as well as specific regulatory requirements. Generally, we recommend a minimum annual inspection to ensure ongoing safety and compliance. For equipment subjected to heavy use or operating in harsh environments, more frequent inspections may be advisable. We offer bespoke advice based on an initial assessment of your equipment's condition and operational demands.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                        What distinguishes FW Plant Inspections from other inspection providers?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>
                                                    
FW Plant Inspections sets itself apart through our depth of expertise, our commitment to employing the latest in inspection technology, and our unwavering focus on customer satisfaction. Our inspectors are not only highly experienced but also specifically trained in access equipment, ensuring a level of insight and precision unmatched in the industry. Additionally, our proactive approach to scheduling and nationwide coverage ensures we provide a seamless service that minimally disrupts your operations. Choosing us means prioritizing safety, efficiency, and peace of mind.</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default AccessEquip;