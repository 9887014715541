import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home1 from './Pages/Home1';

import PlantMachine from './Pages/PlantMachine';
import AccessEquip from './Pages/AccessEquip';

import OurHistory from './Pages/OurHistory';
import FontIcons from './Pages/FontIcons';
import Error from './Pages/Error';

import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import ScrollToTop from './Common/ScrollToTop';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            <Route path='/' element={<Home1/>} />

                            <Route path='/services/plant-and-machinery-inspection' element={<PlantMachine/>} />
                            <Route path='/services/access-equipment-inspection' element={<AccessEquip/>} />

                            <Route path='/our-history' element={<OurHistory/>} />
                            <Route path='/icon-font' element={<FontIcons/>} />
                            <Route path='/error-404' element={<Error/>} />

                            <Route path='/faq' element={<Faq/>} />
                            <Route path='/contact-us' element={<ContactUs/>} />
                            
                            <Route element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;