import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider1 from './../Elements/Slider1';
import About1 from './../Elements/About1';
import Services1 from './../Elements/Services1';

class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider1 />
                    <About1 />
                    <Services1 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home1;