import React from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../Common/Navigation';
import emailjs from '@emailjs/browser';

var bnr = require('./../../images/background/bg-map.png');

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            logo: require('./../../images/logo-main.svg').default, 
            isSearchActive: false 
        };
    }

    state = { isSearchActive: false, isQuoteActive: false };

    handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };

    handleQuoteToggle = () => {
        this.setState({ isQuoteActive: !this.state.isQuoteActive });
    };

    componentDidMount() {
        const handleScroll = () => {
            const offset = window.scrollY;
            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');
            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        };

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
            this.setState({ logo: logopath });
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted'); // Log to confirm function call
        emailjs.sendForm('service_erkftqi', 'template_ykntr3f', event.target, '2mJDQ-4u9aEhTLC3t')
            .then((result) => {
                console.log('SUCCESS!', result.status, result.text);
            }, (error) => {
                console.log('FAILED...', error);
            });
    }

    render() {
        const isSearchActive = this.state.isSearchActive;
        const isQuoteActive = this.state.isQuoteActive;

        return (
            <>
                <header className="site-header header-style-1 nav-wide mobile-sider-drawer-menu">
                    <div className="top-bar bg-gray">
                        <div className="container">
                            <div className="d-flex justify-content-end">
                                <ul className="list-unstyled e-p-bx">
                                    <li><span>Email us:</span> info@fwplantinspection.co.uk</li>
                                    <li><span>Call us:</span> 01462 768 660</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-header main-bar-wraper navbar-expand-lg">
                        <div className="main-bar header-left-gray-block bg-white">
                            <div className="container clearfix">
                                <div className="logo-header">
                                    <div className="logo-header-inner logo-header-one">
                                        <NavLink to={"../"}>
                                            <img src={this.state.logo} alt="FW PLANT INSPECTION" />
                                        </NavLink>
                                    </div>
                                </div>
                                {/* NAV Toggle Button */}
                                <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar icon-bar-first" />
                                    <span className="icon-bar icon-bar-two" />
                                    <span className="icon-bar icon-bar-three" />
                                </button>
                                {/* EXTRA NAV */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <div className="contact-slide-show">
                                            <NavLink to={"#"} className="get-in-touch-btn from-top" onClick={this.handleQuoteToggle}>
                                                Get in touch
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                {/* EXTRA Nav */}
                                {/* MAIN NAVIGATION */}
                                <div className="header-nav nav-dark navbar-collapse collapse justify-content-start collapse">
                                    <Navigation />
                                </div>
                                {/* CONTACT */}
                                <div className="contact-slide-hide" style={{ backgroundImage: 'url(' + bnr + ')', right: isQuoteActive ? '0px' : '100%' }}>
                                    <div className="contact-nav">
                                        <NavLink to={"#"} className="contact_close" onClick={this.handleQuoteToggle}>×</NavLink>
                                        <div className="contact-nav-form">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-12">
                                                    <div className="contact-nav-info">
                                                        <div className="sx-icon-box-wraper left p-b30">
                                                            <div className="icon-xs inline-icon m-b20 scale-in-center"><i className="fa fa-phone" /></div>
                                                            <div className="icon-content">
                                                                <h6 className="m-t0">Phone number</h6>
                                                                <p>01462 768 660</p>
                                                            </div>
                                                        </div>
                                                        <div className="sx-icon-box-wraper left p-b30">
                                                            <div className="icon-xs inline-icon m-b20 scale-in-center"><i className="fa fa-map-marker" /></div>
                                                            <div className="icon-content">
                                                                <h6 className="m-t0">Areas Covered</h6>
                                                                <p>Hertfordshire & Surrounding Areas</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-12 ">
                                                    <div className="contact-nav-field shadow-lg p-a30 bg-white" style={{ backgroundImage: "url(" + bnr + ")" }}>
                                                        <form className="cons-contact-form2 form-transparent" onSubmit={this.handleSubmit}>
                                                            <div className="input input-animate">
                                                                <input defaultValue="" type="text" name="from_name" id="name" required placeholder='Name'/>
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="input input-animate">
                                                                <input type="email" defaultValue="" name="from_email" id="email" required placeholder='Email' />
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="input input-animate">
                                                                <input type="tel" name="phone" defaultValue="" id="number" required placeholder='Phone Number' />
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="input input-animate">
                                                                <textarea name="message" id="message" required defaultValue={""} placeholder='Message'/>
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="text-left p-t10">
                                                                <button name="submit" type="submit" className="site-button btn-half"><span>Submit</span></button>
                                                            </div>
                                                            <input type="hidden" name="to_name" value="Recipient Name" />
                                                            <input type="hidden" name="to_email" value="recipient@example.com" />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    };
};

export default Header;
