import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';
import { ReactComponent as LogoSVG } from '../../images/fw-logo-white.svg'

const currentYear = new Date().getFullYear();


class Footer extends React.Component {
    render() {

        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"/"}>
                                                <LogoSVG className="svg-logo" />
                                            </NavLink>
                                        </div>
                                        <p>FW Plant Inspection, based in Hertfordshire, specialises in thorough plant and machinery inspections to ensure operational safety and efficiency across the surrounding areas.</p>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Useful links</h5>
                                        <ul>
                                            <li><NavLink to={"/services/plant-and-machinery-inspection"}>Plant Inspections</NavLink></li>
                                            <li><NavLink to={"/services/access-equipment-inspection"}>Access Inspections</NavLink></li>
                                            <li><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Regulation Links</h5>
                                        <ul>
                                        <li><a href="https://www.hse.gov.uk/work-equipment-machinery/puwer.htm" target="_blank" rel="noopener noreferrer">PUWER</a></li>
                                        <li><a href="https://www.hse.gov.uk/pubns/books/loler.htm" target="_blank" rel="noopener noreferrer">LOLER</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* CONTACT US */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h5 className="widget-title">Contact Us</h5>
                                        <ul className="widget_address">
                                            <li>info@fwplantinspections.co.uk</li>
                                            <li>01462 768 660</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="sx-footer-bot-left">
                                    <span className="copyrights-text">© {currentYear} FW Plant Inspections. Designed By Louis Barnes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher/>

            </>
        );
    };
};

export default Footer;