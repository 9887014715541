import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStairs, faTruckPickup } from '@fortawesome/free-solid-svg-icons';

const services = [
    {
        count: '01',
        title: 'Plant & Machinery Inspection',
        icon: faTruckPickup,
        description: 'Specialising in thorough inspections of plant and machinery equipment, we ensure operational safety and compliance with industry standards.',
        link: '/services/plant-machinery-inspection',
    },
    {
        count: '02',
        title: 'Access Equipment Inspection',
        icon: faStairs,
        description: 'Our access equipment inspection service offers a comprehensive evaluation of lifts and other access solutions.',
        link: '/services/access-equipment-inspection',
    },
];


class Services1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 bg-gray">
                    <div className="section-content">
                        <div className="container">
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: `url(${require('./../../images/background/cross-line2.png')})` }}>
                                        <h3 className="sep-line-one">Our Services</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper icon-count-2-outer">
                                            <div className="icon-count-2 bg-white">
                                                <span className="icon-count-number">{item.count}</span>
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell">
                                                        <FontAwesomeIcon icon={item.icon} size="4x" />
                                                    </span>
                                                </div>
                                                <div className="icon-content">
                                                    <h4 className="sx-tilte">{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    <div className="text-left">
                                                        <NavLink to={item.link} className="site-button-link">View Service</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
    }
}

export default Services1;
