import React from 'react';
import { NavLink } from 'react-router-dom';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/gallery/banner-1.png');

class PlantMachine extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                <Banner title="Plant & Machinary Inspections" pagename="Plant & Machinary Inspections" description="Ensure your equipment meets safety standards with our Plant & Machinery Inspections. Expert checks for access equipment & more. Book now for peace of mind." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* GALLERY CONTENT START */}
                            <div className="row">
                                <div className="col-lg-4 col-md-12 sticky_column">
                                    <div className="about4-section m-b20 bg-gray p-a20">
                                        <div className="widget widget_services">
                                            <h4 className="widget-title">Other Services</h4>
                                            <ul className="bg-white">
                                                <li><NavLink to={"/services/plant-and-machinery-inspection"}>Plant & Machinary Inspections</NavLink></li>
                                                <li><NavLink to={"/services/access-equipment-inspection"}>Access Equipment Installation</NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="about4-content m-t30">
                                            <h4>BOOK YOUR INSPECTION TODAY</h4>
                                            <p>If you require plant and machinary inspections please get in touch, we can help!</p>
                                            <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        <div className="service-single-block m-b30">
                                            <div className="sx-media">
                                                <img src={require('./../../images/gallery/banner-1.png')} alt="" />
                                            </div>
                                            <h3 className="m-t30 sx-tilte">Safe & Certified: Expert Plant & Machinery Inspections</h3>
                                            <p>At the forefront of industrial safety and performance standards, our Plant and Machinery Inspection services embody excellence and precision. Catering to a diverse range of access equipment and heavy machinery, we offer unrivalled expertise in ensuring compliance with the most stringent regulatory requirements. Our seasoned professionals employ the latest methodologies and technologies to conduct thorough evaluations, aiming to preemptively identify and rectify potential vulnerabilities. By entrusting us with your equipment's inspection, you guarantee operational integrity, minimise risk, and uphold the highest safety protocols for your workforce. Engage our services to safeguard your assets and ensure regulatory adherence with uncompromising professionalism.</p>
                                            <h3 className="m-t30 sx-tilte">Why Choose FW Plant inspections?</h3>
                                            <p>Choosing FW Plant Inspections means opting for unparalleled expertise in the field of plant and machinery safety. Our team of inspectors is comprised of industry veterans who bring a deep understanding of the intricacies involved in operating and maintaining a wide array of equipment. We pride ourselves on delivering a comprehensive suite of services that cater specifically to the unique needs of your business, employing the latest in innovative inspection technologies to ensure accurate, efficient assessments. At the heart of our operation is a steadfast commitment to safety, ensuring that every piece of equipment we inspect meets the highest standards of operational efficiency and regulatory compliance. We understand the importance of navigating the complex landscape of UK and international regulations, and our team is adept at ensuring your assets remain compliant, safeguarding your operations against potential legal challenges. Our customer-centric approach is designed to foster lasting relationships, offering flexible scheduling and nationwide service to meet your needs on your terms. By choosing FW Plant Inspections, you're not just meeting legal obligations—you're ensuring the safety of your operations, your assets, and most importantly, your people.</p>
                                            {/* <div className="sx-media sx-img-effect zoom-slow">
                                                <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                                                    <img src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg" alt=""/>
                                                    <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal6">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>
                                                </div>
                                            </div> */}
                                        </div>
                                        <h4 className="m-t30 sx-tilte">Plant & Machinary Inspections FAQ's</h4>
                                        <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                        What types of equipment do FW Plant Inspections cover?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15"><p>
Our services encompass a wide range of plant and machinery, including but not limited to, access equipment, heavy machinery, lifting equipment, and any industrial machinery requiring adherence to safety standards. We tailor our inspections to meet the specific demands of your operations, ensuring all your assets are comprehensively evaluated for safety and compliance.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                        How often should I have my equipment inspected by FW Plant Inspections?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>
The frequency of inspections depends on several factors, including the type of equipment, its usage intensity, and regulatory requirements. Generally, we recommend an annual inspection as a minimum to ensure ongoing compliance and safety. However, for equipment under heavy use or in critical operations, more frequent inspections may be necessary. Our team can provide a personalized consultation to determine the optimal inspection schedule for your assets.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                        What differentiates FW Plant Inspections from other inspection services?
                                                            <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>What differentiates FW Plant Inspections from other inspection services?
FW Plant Inspections stands out through our deep industry expertise, comprehensive service offerings, and cutting-edge inspection technologies. Our inspectors are seasoned professionals with a detailed understanding of regulatory compliance across various sectors. Additionally, our commitment to customer service, including flexible scheduling and nationwide coverage, ensures that we provide not just inspections, but solutions tailored to your business needs. Choose us for a partnership that values safety, efficiency, and regulatory compliance above all.</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default PlantMachine;